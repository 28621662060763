import React, { useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Table from 'react-bootstrap/Table';
import Logo from '../../../../images/logo192.png';
import { selectors as selectorAuditlog } from '../../../../features/auditLog';
import { selectors as selectorToken } from '../../../../features/token';
import loadAuditLogOffersByAuctionId from '../../../../services/loadAuditLogOffersByAuctionId';

const BidsHistory = () => {
  const dispatch = useDispatch();
  const match = useRouteMatch('/:auctionId');
  const { auctionId } = match.params;
  const token = useSelector(selectorToken.getToken);
  const offers = useSelector(selectorAuditlog.getHistoryOffers);

  useEffect(() => {
    loadAuditLogOffersByAuctionId(auctionId, token, dispatch);
  }, [auctionId, dispatch, token]);

  return (
    <div className="bg-white col-lg-12 mt-5 mb-5 pb-4 rounded pl-0 pr-0">
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-4 pl-4 border-bottom">
        <h1 className="h4 font-weight-bold mb-4 ml-3">Historia bidovania</h1>
      </div>
      <Table responsive striped hover className="mt-4 mb-5 col-lg-12">
        <thead className="bg-white">
          <tr className="bg-white text-blue">
            <th>Čas</th>
            <th>Posledné prihadzovania</th>

            <th>Prihodená suma</th>
          </tr>
        </thead>
        <tbody>
          {offers != null
            ? offers?.criteriaOffer?.map((d, index) => (
              <tr key={d.createDateTime + index}>
                <td className="pt-4 pb-4">{d.createDateTime}</td>
                <td className="pt-2 pb-2 row">
                  {' '}
                  <img
                    src={Logo}
                    className="img-fluid align-self-center float-right img-thumbnail rounded-circle"
                    alt=""
                  />
                  <div className="card-body col-lg-8">
                    <p className="card-subtitle text-blue2 font-weight-bolder ">
                      {d.username}
                    </p>
                    <h6 className="card-text font-weight-lighter text-muted ">
                      {d.organizationName}
                    </h6>
                  </div>
                </td>

                <td className="text-primary pt-4 pb-4">{d.value}</td>
              </tr>
            ))
            : null}
        </tbody>
      </Table>
    </div>
  );
};
export default BidsHistory;
