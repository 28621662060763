export const OFFERS_UPDATE = 'OFFERS_UPDATE';
export const OFFERS_VALUES = 'OFFERS_VALUES';
export const OFFERS_SHOW_LOADING = 'OFFERS_SHOW_LOADING';
export const OFFERS_HIDE_LOADING = 'OFFERS_HIDE_LOADING';
export const OFFERS_SET_INITIAL = 'OFFERS_SET_INITIAL';
export const OFFERS_SELECTED_OFFERS = 'OFFERS_SELECTED_OFFERS';
export const OFFERS_SELECTED_OFFERS_NULL = 'OFFERS_SELECTED_OFFERS_NULL';
export const OFFERS_ADD_SHOW_LOADING = 'OFFERS_ADD_SHOW_LOADING';
export const OFFERS_ADD_HIDE_LOADING = 'OFFERS_ADD_HIDE_LOADING';
export const OFFERS_START_LOAD = 'OFFERS_START_LOAD';
