const SETTINGS = {
  VERSION: '0.2.8-dev',
  // každú minútu
  TOKEN_VALIDATION_INTERVAL: 1000 * 30,
  TOKEN_COOKIE_VALIDATION_INTERVAL: 1000 * 2,
  WEBSOCKET_URL: 'wss://aukcie.tenderia.sk/websocket',
  SERVICE_URL: 'https://aukcie.tenderia.sk/api/ws',
  //WEBSOCKET_URL: 'ws://localhost:8080/e-lena-auction-1.0-SNAPSHOT/websocket',
  //SERVICE_URL: 'http://localhost:8080/e-lena-auction-1.0-SNAPSHOT/ws',
  SERVICES: {
    CHECK_AUCTION_STATE: '/user/checkAuctionState',
    LOAD_AUDIT_LOG: '/history/loadAuditLogByAuctionId',
    LOAD_AUDIT_LOG_BY_ID: '/history/loadAuditLogById',
    LOAD_AUDIT_LOG_OFFERS: '/history/loadAuditLogOffersByAuctionId',
    LOAD_ALL_CPV_CODES: '/auction/loadAllCPVCodes',
    LOAD_MENU_STATE: '/user/loadMenuState',
    EDIT_MENU_STATE: '/user/editMenuState',
    START_AUCTION: '/auctionhall/startAuction',
    STOP_AUCTION: '/auctionhall/stopAuction',
    LOAD_AUCTION: '/auction/loadAuction',
    EDIT_AUCTION: '/auction/editAuction',
    EDIT_AUCTION_ITEMS: '/auction/editAuctionItems',
    EDIT_AUCTION_CRITERIA: '/auction/editAuctionCriteria',
    EXPORT_TO_PDF: '/auction/exportToPdf',
    LOAD_AUCTION_ROUND: '/user/loadAuctionRounds',
    LOAD_ACTIVE_AUCTION_ROUND: '/auction/round/loadActiveAuctionRound',
    EDIT_AUCTION_ROUND: '/auction/round/editAuctionRound',
    DELETE_AUCTION_ROUND: '/auction/round/deleteAuctionRound',
    DELETE_AUCTION_CRITERIA: '/auction/deleteAuctionCriteria',
    DELETE_AUCTION_ITEM: '/auction/deleteAuctionItem',
    ADD_AUCTION_ROUND: '/auction/round/addAuctionRound',
    LOAD_AUCTION_SETTINGS: '/auction/loadAuctionSettings',
    LOAD_ORGANISATIONS: '/organization/loadOrganisations',
    EDIT_ORGANISATIONS: '/organization/editOrganisation',
    DEFINE_AUCTION_SETTINGS: '/auction/defineAuctionSettings',
    LOAD_OFFERS_BY_ORGANIZATION: '/offer/loadOfferByOrganizationAndAuctionId',
    LOAD_OFFERS: '/offer/loadOffer',
    ADD_OFFERS: '/offer/addOffer',
    EDIT_OFFER: '/offer/updateOffer',
    LOAD_USER_AUCTION_ROUND: '/user/loadAuctionRounds',
    LOAD_USER_AUCTION_DATA: '/user/loadMyAuctionData',
    LOGIN: '/session/login',
    LOGOUT: '/session/logout',
    TOKEN_VALIDATION: '/session/tokenValidation',
    LOAD_START_OFFERS: '/history/loadCriteriaOffers'
  },
};

export default SETTINGS;
